import TariffTitle from '../routePlanner/TariffTitle';
import Counter from '../search/Counter';
import { SearchQuery } from '../templates/SearchBox';
import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { Popover } from '@mui/material';
import { useTariffs, useUser } from '@web/shop-logic';
import useModal from '@/components//modal/useModal';
import { Button } from '@/components/atoms/Button';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import { SearchBoxPopper } from '@/components/atoms/Popper';
import { getStringifyTariffs } from '@/components/atoms/StringifiedTariffs';
import ModalContext from '@/components/modal/ModalContext';
import PopoverHeader from '@/components/organisms/PopoverHeader';
import TariffSelectAfterLoginModal from '@/components/organisms/TariffSelectAfterLoginModal';
import Tariff from '@/components/registration/tariffOptions';
import Passengers from '@/components/search/Passengers';
import URLS from '@/constants/url';
import useContextSelector from '@/hooks/useContextSelector';
import useMobileWidth from '@/hooks/useMobileWidth';
import { ArrowDropDown, Person } from '@/icons';
import { Favorite, PersonFree } from '@/icons';
import { escapeHtmlBrackets } from '@/utils/dataUtils';
import { buildQuery, createParamArray, isQueryValid } from '@/utils/routerUtils';

interface TariffSelectProps {
  onTariffsChanged: (tariffs: string[]) => void;
  onInteracted?: (open: boolean) => void;
  isRouter?: boolean;
}

export type TariffTypeToJsx = Record<TariffType, JSX.Element>;
export type TariffTypeToTariff = Record<TariffType, Tariff[]>;

export enum TariffType {
  Basic = 'basic',
  Rest = 'rest',
}

const iconClassName = 'w-2.5 h-2.5 mr-1';

const Icon = {
  [TariffType.Basic]: <Favorite className={classNames(iconClassName, 'fill-secondary-red')} />,
  [TariffType.Rest]: <PersonFree className={classNames(iconClassName, 'fill-primary-yellow')} />,
};

const Title = {
  [TariffType.Basic]: <Trans i18nKey="passengerSelect.title.basic" />,
  [TariffType.Rest]: <Trans i18nKey="passengerSelect.title.rest" />,
};

export const BasicTariffs = [
  Tariff.Regular,
  Tariff.Isic,
  Tariff.CzechStudentPass26,
  Tariff.CzechStudentPass15,
  Tariff.ChildUnder12,
  Tariff.AttendedChild,
];
export const NotRestTariffs = [
  Tariff.Disabled,
  Tariff.DisabledAttendance,
  Tariff.Euro26,
  Tariff.Disabled3,
];

const TariffSelect: React.FC<TariffSelectProps> = ({
  onTariffsChanged,
  onInteracted,
  isRouter,
}) => {
  const { isMobile } = useMobileWidth();
  const { user, isLoggedIn } = useUser();
  const { push, query } = useRouter();
  const { showModal } = useModal();
  const hideAllModals = useContextSelector(ModalContext, (c) => c.hideAllModals);
  const { t } = useTranslation();
  const { data = [] } = useTariffs();
  const { values } = useFormikContext<SearchQuery>();
  const tariffsAll = data.map(({ key }) => key);

  const [disabledAttendance] = useLocalStorage('disabled-attendance');
  const [anchorTariffEl, setAnchorTariffEl] = useState<HTMLElement | null>(null);
  const [anchorTariffPop, handleTariffPop] = useState<HTMLElement | null>(null);
  const [checkUserTarif, setCheckUserTarif] = useState(false);
  const [afterLoginModal, setAfterLoginModal] = useState(null);

  /**
   * V případě že YBUS změní název tarifu nebo ho úplně odstraní a uživatel ho má přednastavený jako
   * defaultní, nastaví se mu jako defaultní tarif REGULAR (pokud existuje) nebo první tarif v seznamu.
   */
  const setOptionalFallback = () => {
    if (user.user && data?.length) {
      const tariffExist = data?.some((tariff) => tariff.key === user.user.defaultTariffKey);

      if (!tariffExist)
        onTariffsChanged([
          data.find((tariff) => tariff.key === Tariff.Regular) ? Tariff.Regular : data[0].key,
        ]);
    }
  };

  const handleClose = () => {
    if (!values.tariffs.length) setOptionalFallback();

    if (isMobile) {
      setAnchorTariffEl(null);
    } else {
      handleTariffPop(null);
    }
  };

  const stringifyTariffs = data.length
    ? getStringifyTariffs(values.tariffs, data)
        .map(({ title }) => title)
        .join(', ')
    : values.tariffs[0];

  useEffect(() => {
    if (disabledAttendance) {
      onTariffsChanged([Tariff.Disabled, Tariff.DisabledAttendance]);
    }
  }, [disabledAttendance]);

  useEffect(() => {
    if (query.tariffs) onTariffsChanged(createParamArray(query.tariffs) as Tariff[]);
  }, [query.tariffs]);

  useEffect(() => setOptionalFallback(), [user, data]);

  useEffect(() => !isRouter && onInteracted(!!anchorTariffPop), [anchorTariffPop]);

  useEffect(() => {
    /**
     * Po přihlášení nabídne uživatelům změnit tarif (z Nastavení účtu),
     * pokud:
     *  - je uživatel na stránce s výsledky vyhledávání (ignoruje HP)
     *  - je počet cestujících 1
     *  - je použit jiný tarif než v Nastavení účtu
     * Kontrola/změna tarifu se provede pouze jednorázově při přihlášení
     */
    if (isLoggedIn && !checkUserTarif) {
      // Kontrola tarifu proběhla
      setCheckUserTarif(true);

      const userTarif = user.user?.defaultTariffKey || Tariff.Regular;
      const searchTarif = values.tariffs[0];

      if (values.tariffs.length === 1 && userTarif !== searchTarif) {
        /**
         * Stránka s výsledky vyhledávání: zobraz modal
         */
        if (isQueryValid(query, 'search')) {
          setAfterLoginModal(
            // Sestavení modalu s nabídkou
            <TariffSelectAfterLoginModal
              userTarif={escapeHtmlBrackets(t(`tariff.${userTarif}`))}
              searchTarif={escapeHtmlBrackets(t(`tariff.${searchTarif}`))}
              handleUserTarif={() => {
                onTariffsChanged([userTarif]);
                push({
                  pathname: URLS.HOMEPAGE,
                  query: buildQuery({ ...query, tariffs: [userTarif] }),
                });
              }}
              handleSearchTarif={hideAllModals}
            />,
          );
        } else {
          /**
           * Homepage: rovnou změň tarif
           */
          onTariffsChanged([userTarif]);
        }
      }
    }
  }, [query, isLoggedIn, checkUserTarif, values.tariffs]);

  /**
   * Zobrazení modalu s nabídkou změny tarifu
   */
  useEffect(() => {
    if (afterLoginModal) {
      showModal(afterLoginModal, { size: 'big', removePadding: true });
    }
  }, [afterLoginModal]);

  const TariffsByType = {
    [TariffType.Basic]: BasicTariffs.filter((tar) => tariffsAll.includes(tar)),
    [TariffType.Rest]: NotRestTariffs.filter((tar) => tariffsAll.includes(tar)),
  };

  const renderTariff = (tariff, index, { length }) => (
    <div
      className={classNames(
        'flex justify-between items-center gap-2 px-1.5 pt-1.5',
        index === length - 1 ? 'pb-1.5' : undefined,
      )}
      key={tariff}
    >
      <div>
        <Trans i18nKey={`tariff.${tariff}`} />
        <div className="text-neutral-gray">
          <Trans i18nKey={`tariff.${tariff}.detail`} />
        </div>
      </div>
      <Counter
        className="flex items-center"
        tariff={tariff}
        value={values.tariffs.filter((tar) => tar === tariff).length}
        maxValue={6 - values.tariffs.filter((tar) => tar !== tariff).length}
        minValue={0}
        onCounterChange={(newTariffs) =>
          onTariffsChanged([...values.tariffs.filter((tar) => tar !== tariff), ...newTariffs])
        }
        ariaTranslationIncrease="accessibility.button.PassengerAdd"
        ariaTranslationDecrease="accessibility.button.PassengerRemove"
      />
    </div>
  );

  return (
    <div className={classNames(isRouter ? 'w-full' : 'sm:w-full lg:w-52 flex-1 lg:mx-0.5')}>
      {isMobile && !isRouter && (
        <>
          <ClickableDiv
            className={classNames(
              'rounded-sm bg-neutral-white flex items-center justify-between h-12',
              anchorTariffEl ? 'clickableDiv-container-open' : 'clickableDiv-container',
            )}
            data-id="tariff-select"
            onClick={(e) => setAnchorTariffEl(e.target as HTMLElement)}
            aria-label={stringifyTariffs}
          >
            <div className="flex items-center">
              <Person className="w-2.5 h-2.5 mx-1 fill-neutral-gray2" />
              <div className="text-14">
                <span
                  className={classNames(
                    'clickableDiv-placeholder',
                    values.tariffs ? 'selected' : 'default',
                  )}
                >
                  <Trans i18nKey="passengers" />
                </span>
                <span className="clickableDiv-input">
                  <Trans
                    i18nKey="searchbox.compact.passengers"
                    values={{ count: values.tariffs?.length || 1 }}
                  />
                </span>
              </div>
            </div>
            <ArrowDropDown className="w-3 h-3 mr-1 fill-neutral-gray2" />
          </ClickableDiv>
          <Popover
            anchorEl={anchorTariffEl}
            anchorReference="none"
            transitionDuration={0}
            open={!!anchorTariffEl}
            onClose={handleClose}
            PaperProps={{ className: 'cssreset-popover-paper' }}
          >
            <PopoverHeader i18nKey="passengers" handleBack={handleClose} />
            <Passengers
              renderTariff={renderTariff}
              tariffs={values.tariffs}
              TariffsByType={TariffsByType}
              Icon={Icon}
              Title={Title}
              BasicTariffs={BasicTariffs}
              NotRestTariffs={NotRestTariffs}
            />
            <div className="fixed bottom-0 left-0 z-10 flex items-center justify-center w-full p-2 bg-white lg:shadow-popper fullhd:shadow-popper">
              <Button variant="primary" onClick={handleClose} className="w-full">
                <Trans i18nKey="payments.giftCard.button.confirm" />
              </Button>
            </div>
          </Popover>
        </>
      )}{' '}
      {!isMobile && !isRouter && (
        <SearchBoxPopper
          content={
            <Passengers
              renderTariff={renderTariff}
              tariffs={values.tariffs}
              TariffsByType={TariffsByType}
              Icon={Icon}
              Title={Title}
              BasicTariffs={BasicTariffs}
              NotRestTariffs={NotRestTariffs}
            />
          }
          open={!!anchorTariffPop}
          onClose={handleClose}
          placement="bottom-start"
        >
          <div>
            {/**
             * TODO: odstranit ClickableDiv aby byla využívaná jen komponenta Popper
             * nyní zůstává kvůli full screen mobile zobrazení
             */}
            <ClickableDiv
              className={classNames(
                'rounded-sm bg-neutral-white flex items-center justify-between h-12',
                anchorTariffPop ? 'clickableDiv-container-open' : 'clickableDiv-container',
              )}
              onClick={(e) => handleTariffPop(e.target as HTMLElement)}
              onBlur={async (event) => {
                /**
                 * JS Hack
                 * Poté, co komponenta (input) ztratí focus, ověří se,
                 * jestli nově focusovaný element je součástí otevřeného popperu
                 * Pokud není (např. uživatel zmáčkl tab a focus je na tlačítku),
                 * vynutí se zavření komponenty
                 */
                const activeElement = event?.nativeEvent?.relatedTarget;

                if (activeElement instanceof Element) {
                  const parentPopper = activeElement?.closest('.shadow-popper');
                  // V Safari je event.relatedTarget vždy null, nespouštěj zavření
                  if (activeElement && !parentPopper) handleClose();
                }
              }}
              aria-label={stringifyTariffs}
            >
              <div className="flex items-center w-full">
                <Person className="w-3 h-3 mx-1 fill-neutral-gray2" />
                <div className="tariff-select text-14">
                  <span
                    className={classNames(
                      'clickableDiv-placeholder',
                      values.tariffs ? 'selected' : 'default',
                    )}
                  >
                    <Trans i18nKey="passengers" />
                  </span>
                  <span className="clickableDiv-input">
                    <Trans
                      i18nKey="searchbox.compact.passengers"
                      values={{ count: values.tariffs?.length || 1 }}
                    />
                  </span>
                </div>
              </div>
              <ArrowDropDown className="w-3 h-3 mr-1 fill-neutral-gray2" />
            </ClickableDiv>
          </div>
        </SearchBoxPopper>
      )}
      {isRouter &&
        Object.values(TariffType).map((type) =>
          TariffsByType[type].length ? (
            <>
              <TariffTitle
                count={values?.tariffs?.reduce(
                  (prev, curr) => prev + (TariffsByType[type].includes(curr) ? 1 : 0),
                  0,
                )}
              >
                {Icon[type]}
                {Title[type]}
              </TariffTitle>
              <div className="px-2">{TariffsByType[type].map(renderTariff)}</div>
            </>
          ) : null,
        )}
    </div>
  );
};

export default TariffSelect;
